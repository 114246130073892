import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../parceriaInfo';
import { DetalheParceriaApi } from '../../../apis/detalheParceriaApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { DevolucaoAplicacao } from './devolucaoAplicacao';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from '../../../@core/date-converter';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import { UserService } from '../../../@core/user.service';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { ImagensUrlData } from 'src/app/@shared/imagens-urldata';
import { PesquisaData } from '../../pesquisaData';
import { DevolucaoAplicacaoSend } from './devolucaoAplicacaoSend';
import * as Json2csvParser from 'json2csv';
import { ExcelService } from 'src/app/@shared/excel-service';
import saveAs from 'file-saver';
import { DatePipe } from '@angular/common';
import { PaginationInstance } from 'ngx-pagination';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { Utils } from 'src/app/@shared/utils';
import { FiltroDevolucao } from './filtroDevolucao';

@Component({
  selector: 'app-devolucao-parceria',
  templateUrl: './devolucaoRecursos.component.html',
  styleUrls: ['./../detalheParceria.component.scss'],
  providers: [DatePipe]
})
export class DevolucaoRecursosComponent implements OnInit, OnChanges {

  @Input()
  private parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  devolucoesAplicacoes: Array<DevolucaoAplicacao> = [];
  mascaraData = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  devolAplicacaoSend: DevolucaoAplicacaoSend;
  showCamposEdicao: boolean = false;
  showUpload: boolean = false;
  modalEdicaoReference: any;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  pesquisaData: PesquisaData;
  execDevolRecursos: number;
  execTotQtd: number;
  execDevolAplic: number;
  execDevolParceria: number;
  arquivo: string;
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  p = 1;
  pagina: number;
  filtro: FiltroDevolucao;
  @Input()
  isAuditorExterno: boolean;
  username: string;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1
  };

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    public userService: UserService,
    private mascaras: Mascaras,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private utils: Utils) {
  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.setupInterface();
  }

  setupInterface() {
    this.loading.next(true);
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    console.log(this.isFiscal);
    if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
      const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
      this.pesquisaData = new PesquisaData();
      this.pesquisaData.dataInicial = myDate;
      this.pesquisaData.dataFinal = new Date();
    }
    this.inicializaFiltro();
    this.getDevolucaoParceria(this.filtro);
  }

  inicializaFiltro() {
    if (this.filtro === undefined) {
      this.filtro = new FiltroDevolucao();
      this.filtro.parId = this.parceriaInfo.parId;
      this.filtro.devolId = null;
      this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
      this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
      this.filtro.motivo = null;
      this.filtro.data = null;
    }
  }

  getDevolucaoParceria(filtro) {
    const filtroD = JSON.stringify(filtro);
    this.parceriaDespesaDevolApi.getDevolucaoAplicacaoPeriodo(filtroD, this.isPortal).subscribe((response) => {
      if (response) {
        this.devolucoesAplicacoes = response as Array<DevolucaoAplicacao>;
        console.log(this.devolucoesAplicacoes);
        this.execDevolRecursos = 0;
        this.execTotQtd = 0;
        this.execDevolAplic = 0;
        this.execDevolParceria = 0;

        const cloneArray = this.devolucoesAplicacoes.slice();
        this.devolucoesAplicacoes.forEach(element => {
          if ((element.execDevolJustificaExclusao !== null) && (this.isPortal || (!this.isSMTC && !this.isFiscal))) {
            cloneArray.splice(cloneArray.indexOf(element), 1);
          }
        }, []);
        this.devolucoesAplicacoes = cloneArray;
        this.devolucoesAplicacoes.forEach(element => {
          if (element.execDevolJustificaExclusao == null) {
            switch (element.execDevolMotivo) {
              case 1: {
                this.execDevolRecursos = this.execDevolRecursos + element.execDevolValor;
                this.execTotQtd++;
                break;
              }
              case 2: {
                this.execDevolAplic = this.execDevolAplic + element.execDevolValor;
                this.execTotQtd++;
                break;
              }
              case 3: {
                this.execDevolParceria = this.execDevolParceria + element.execDevolValor;
                this.execTotQtd++;
                break;
              }
            }
          }
        });
        //console.log(this.devolucoesAplicacoes);
      } else {
        this.alertService.warning('Nenhum registro encontrado');
      }
      if (this.modalEdicaoReference != undefined)
        this.modalEdicaoReference.close();
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }
  pageChanged(event) {
    //console.log(event);
    this.config.currentPage = event;
  }

  disableCampos(devolucoesAplicacoesSend) {
    if (devolucoesAplicacoesSend.execConferir === 2 || devolucoesAplicacoesSend.execConferir === 4
      || devolucoesAplicacoesSend.execConferir === 5) {
      return true;
    }
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  disableApagarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  visualizarBotao(devolucoesAplicacoesSend) {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno) || ((!this.isFiscal && (devolucoesAplicacoesSend.execConferir === 2
      || devolucoesAplicacoesSend.execConferir === 4 || devolucoesAplicacoesSend.execConferir === 5 || devolucoesAplicacoesSend.execConferir === 7))));
  }

  enableEditarIcone(devolAplicacao) {
    if (this.isConsulta || this.isSMTC || this.isPortal || this.isAuditorExterno) {
      return false;
    }
    return (devolAplicacao.execDevolJustificaExclusao == null && this.isFiscal) ||
      (!this.isFiscal && (devolAplicacao.execConferir == 1 || devolAplicacao.execConferir == 3 ));  // || devolAplicacao.execConferir == 7));  entidade
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {

      if (changes.hasOwnProperty(property) && property === 'pesquisaData') {
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
          const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
          this.pesquisaData = new PesquisaData();
          this.pesquisaData.dataInicial = myDate;
          this.pesquisaData.dataFinal = new Date();
        }
        if (this.pesquisaData.dataFinal === undefined) {
          this.pesquisaData.dataFinal = new Date();
        }
        this.config.currentPage = 1;
        this.aplicaFiltroDev();
      }
    }
  }


  adicionaDevolAplic() {
    const dev = new DevolucaoAplicacao();
    dev.execConferir = 1;
    this.devolucoesAplicacoes.push(dev);
  }

  getArquivoDevApl(parId, execDevolId, execDevolArquivo) {
    this.loading.next(true);

    this.detalheParceriaApi.getArquivoDevApl(parId, execDevolId)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          //a.download = this.devolucoesAplicacoes[index].execDevolArquivo.trim();
          a.download = execDevolArquivo.trim();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo de devolução.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  onFileDevChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    console.log(fileList);
    const reader = new FileReader();


    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 10) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(1) + " MB");
        return;
      }
      if (array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.devolAplicacaoSend.execDevolDocumento = (reader.result).toString().split(',')[1];
          this.devolAplicacaoSend.execDevolTipo = array[1];
          this.devolAplicacaoSend.execDevolArquivo = fileList[0].name;
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }

  resetDevFile(i) {
    // this.devolucoesAplicacoes[i].execDevolDocumento = undefined;
    this.devolucoesAplicacoes[i].execDevolTipo = undefined;
    this.devolucoesAplicacoes[i].execDevolArquivo = undefined;
  }

  removeDevoAplic(index) {
    this.devolucoesAplicacoes.splice(index, 1);
  }


  updateSituacao(event) {
    const newVal = event.currentTarget.value;
    this.devolAplicacaoSend.execConferir = newVal;
  }

  openExportacao(content, devolucaosRecursos) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: 'modalProcesso' });
  }

  updateMotivoDevApl(event) {
    const newVal = event.currentTarget.value;
    this.devolAplicacaoSend.execDevolMotivo = newVal;
  }

  updateTextAreaJustificativa(valor) {
    this.devolAplicacaoSend.execDevolJustificaExclusao = valor;
  }


  setDevolAplicacao(devolAplicacao) {
    if (devolAplicacao) {
      this.devolAplicacaoSend = devolAplicacao;
      this.showUpload = this.devolAplicacaoSend.execDevolArquivo ? false : true;
      this.devolAplicacaoSend.execDevolDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.devolAplicacaoSend.execDevolData));
      this.devolAplicacaoSend.operacaoData = new Date();
      this.devolAplicacaoSend.operacaoUsuario = this.username;
    } else {
      this.devolAplicacaoSend = new DevolucaoAplicacaoSend();
      this.showUpload = true;
      this.devolAplicacaoSend.parId = this.parceriaInfo.parId;
      this.devolAplicacaoSend.execDevolId = -1;
      this.devolAplicacaoSend.execDevolValor = 0.0;
      this.devolAplicacaoSend.execDevolTipo = '';
      this.devolAplicacaoSend.execConferir = 1;
      this.devolAplicacaoSend.execDevolDevolucao = 0.0;
    }

  }

  async getParceriaInfo(id) {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getParceriaInfo(id)
      .subscribe((response) => {
        if (response) {
          this.parceriaInfo = response as ParceriaInfo;
          this.parceriaInfo.totalDesembolsos = (this.parceriaInfo.execTotVlrPerm + this.parceriaInfo.execTotVlrOutros +
            this.parceriaInfo.execTotVlrPF + this.parceriaInfo.execTotVlrPJ + this.parceriaInfo.execTotVlrConsumo);
          this.parceriaInfo.quantidadeTotal = this.parceriaInfo.execTotQtdPerm + this.parceriaInfo.execTotQtdOutros +
            this.parceriaInfo.execTotQtdPF + this.parceriaInfo.execTotQtdPJ + this.parceriaInfo.execTotQtdConsumo;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

  }

  updateTextArea(valor) {
    this.devolAplicacaoSend.observacao = valor;
  }

  updateTextAreaMotivo(valor) {
    this.devolAplicacaoSend.execMotivo = valor;
  }


  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  salvaDevolAplicacao() {

    if ((this.devolAplicacaoSend.execDevolArquivo != null && this.devolAplicacaoSend.execDevolArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }
    if (!this.isFiscal) {       
      // se não for fiscal, e o status é a corrigir 
      // adiciona o status da situação para a reconferir
     if (this.devolAplicacaoSend.execConferir == 3){
       this.devolAplicacaoSend.execConferir = 7;
     }
   }

    this.devolAplicacaoSend.operacaoData = new Date();
    this.devolAplicacaoSend.operacaoUsuario = this.username;

    if (this.isFiscal && (this.devolAplicacaoSend.execConferir == 3 || this.devolAplicacaoSend.execConferir == 4
      || this.devolAplicacaoSend.execConferir == 5)) {
      if (this.isBlank(this.devolAplicacaoSend.execMotivo)) {
        this.alertService.danger('Campo apontamento deve ser preenchido.');
        return;
      }
    }

    this.devolAplicacaoSend.execDevolData = this.dateConverter.fromNgbDateStructToDate(this.devolAplicacaoSend.execDevolDataFormat);
    if (!this.utils.isValidDate(this.devolAplicacaoSend.execDevolData)) {
      this.alertService.danger('Data Inválida.');
      return;
    }

    this.devolAplicacaoSend.execDevolData = this.dateConverter.fromNgbDateStructToDate(this.devolAplicacaoSend.execDevolDataFormat);
    let data = JSON.stringify(this.devolAplicacaoSend, this.replacerDevolAplicacao);
    this.detalheParceriaApi.updateDevolucaoAplicacao(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setDevolAplicacao(undefined);
        this.modalEdicaoReference.close();
        await this.getParceriaInfo(this.devolAplicacaoSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirDevolucaoAplicacao() {

    // const today = new Date();
    // const year1 = this.devolAplicacaoSend.execDevolDataFormat.year;
    // const year2 = today.getFullYear();
    // const month1 = this.devolAplicacaoSend.execDevolDataFormat.month;
    // const month2 = today.getMonth() + 1;
    // const day2 = today.getDate();

    // const numberOfMonths = (year2 - year1) * 12 + (month2 - month1);
    // if ((numberOfMonths > 1) || (numberOfMonths === 1 && day2 > 10)) {
      if (this.isBlank(this.devolAplicacaoSend.execDevolJustificaExclusao)) {
        this.alertService.danger('A justificativa é obrigatória para a exclusão da devolução dos recursos e/ou aplicação/poupança.');
        return;
      }
      else {
        this.devolAplicacaoSend.execDevolJustificaExclusao = " ";
      }

      this.devolAplicacaoSend.operacaoData = new Date();
      this.devolAplicacaoSend.operacaoUsuario = this.username;
      this.devolAplicacaoSend.execDevolData = this.dateConverter.fromNgbDateStructToDate(this.devolAplicacaoSend.execDevolDataFormat);
      if (!this.utils.isValidDate(this.devolAplicacaoSend.execDevolData)) {
        this.alertService.danger('Data Inválida.');
        return;
      }

      let data = JSON.stringify(this.devolAplicacaoSend, this.replacerDevolAplicacao);
      this.detalheParceriaApi.updateDevolucaoAplicacao(data).subscribe(async (response) => {
        if (response) {
          this.setupInterface();
          this.setDevolAplicacao(undefined);
          this.modalEdicaoReference.close();
          this.getDevolucaoParceria(this.filtro);
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
        this.modalEdicaoReference.close();
      }, () => this.loading.next(false));
    // } else {
    //   this.detalheParceriaApi.excluirDevolucaoAplicacao(this.devolAplicacaoSend).subscribe((response) => {
    //     if (response) {
    //       this.setupInterface();
    //       this.setDevolAplicacao(undefined);
    //       this.modalEdicaoReference.close();
    //     }
    //   }, (response) => {
    //     if (response.status >= 500) {
    //       this.alertService.danger(response.error);
    //     } else {
    //       this.alertService.warning(response.error);
    //     }
    //     this.loading.next(false);
    //   }, () => this.loading.next(false));
    // }
  }

  replacerDevolAplicacao(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['execDevolDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  async getFile(parId, execId) {
    this.detalheParceriaApi.getArquivoDevApl(parId, execId)
      .subscribe((response) => {
        if (response) {
          //console.log(response);
          if (response.body.size > 0) {
            this.arquivo = URL.createObjectURL(response.body);
          } else {
            this.arquivo = '';
          }

        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo de devolução.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  openEdicaoDevolAplicacao(content, devolAplicacao) {
    this.setDevolAplicacao(devolAplicacao);
    if (this.devolAplicacaoSend.execDevolId !== -1) {
      this.getFile(this.devolAplicacaoSend.parId, this.devolAplicacaoSend.execDevolId);
    }
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: 'modalProcesso' });
  }

  openExcluirDevolAplicacao(content, devolAplicacao) {
    this.setDevolAplicacao(devolAplicacao);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  async exportAs(type, dtIni, dtFim) {
    let nome;
    nome = 'Dem_Devol_Aplic_' + this.parceriaInfo.parOrgCnpj;
    if (dtIni === undefined) {
      dtIni = this.pesquisaData.dataInicial;
      dtFim = this.pesquisaData.dataFinal;
    }
    else {
      dtIni = this.dateConverter.fromNgbDateStructToDate(dtIni);
      if (dtFim === undefined) {
        dtFim = new Date();
      }
      else {
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
    }
    this.parceriaDespesaDevolApi.getDevolucaoAplicacaoPeriodo(this.filtro, this.isPortal).subscribe(async (response) => {
      if (response) {

        const devolucao = response as DevolucaoAplicacao[];

        devolucao.forEach(element => {
          // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
          //element.execValorDoc = Mascaras.aplicaMascaraReais(element.execValorDoc);
          const datePipe = new DatePipe('en-US');
          element.execDevolDataStr = datePipe.transform(element.execDevolData, 'dd/MM/yyyy');
          element.observacao = this.utils.getExecConferirStr(element.execConferir);
          element.operacaoUsuario = this.utils.getMotivoStr(element.execDevolMotivo);
        });

        const devolucaoExc = new Array<DevolucaoAplicacao>();
        devolucao.forEach(element => {
          if (element.execDevolJustificaExclusao == null) {
            devolucaoExc.push(element);
          }
        });
        if (type === 'xlsx') {
          const newJson = devolucaoExc.map(rec => {
            return {
              'Data': rec.execDevolDataStr,
              'Valor': rec.execDevolValor,
              'Motivo': rec.operacaoUsuario,
              'Situação': rec.observacao,
              'Documento': rec.execDevolArquivo
            };
          });
          this.excelService.exportAsExcelFile(newJson, nome);
        } else if (type === 'csv') {
          const fields = [{
            label: 'Data',
            value: 'execDevolDataStr'
          }, {
            label: 'Valor',
            value: 'execDevolValor'
          }, {
            label: 'Motivo',
            value: 'operacaoUsuario'
          }, {
            label: 'Situação',
            value: 'observacao'
          }, {
            label: 'Documento',
            value: 'execDevolArquivo'
          }
          ];
          const json2csvParser = new Json2csvParser.Parser({ fields });
          const csv = json2csvParser.parse(devolucaoExc);
          const blob = new Blob([csv], { type: 'text/csv' });
          saveAs(blob, nome + '.csv');
        }
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  public async generatePDF(dtIni, dtFim) {

    this.loading.next(true);
    let data;
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF

    pdf.setFont('helvetica');
    pdf.setLineWidth(0.5);
    let nome;
    let columns;
    let rows;


    const brasao = new ImagensUrlData().getBrasaoSMF();
    pdf.addImage(brasao, 'JPEG', 5, 4, 33, 13);
    nome = 'Demonstrativo de Devolução e/ou Aplicação/Poupança' + this.parceriaInfo.parOrgCnpj + '.pdf';
    pdf.setFontSize(12);
    pdf.setFontType('bold');
    pdf.text(50, 10, 'Demonstrativo de Devolução e/ou Aplicação/Poupança');
    pdf.setFontSize(9);
    pdf.setFontType('normal');
    pdf.text(40, 14, this.parceriaInfo.parOrgNome.concat(' - ')
      .concat(this.mascaras.aplicaMascaraDocumento(this.parceriaInfo.parOrgCnpj.toString(), 2)));
    pdf.text(160, 18, 'Data/Hora: ' + this.datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss'));
    pdf.text(40, 18, 'Termo: ' + this.parceriaInfo.parNum);

    if (dtIni === undefined) {
      dtIni = this.pesquisaData.dataInicial;
      dtFim = this.pesquisaData.dataFinal;
    }
    else {
      dtIni = this.dateConverter.fromNgbDateStructToDate(dtIni);
      if (dtFim === undefined) {
        dtFim = new Date();
      }
      else {
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
    }
    pdf.text(80, 18, 'Período: ' + this.datePipe.transform(dtIni, 'dd/MM/yyyy') + '  a ' + this.datePipe.transform(dtFim, 'dd/MM/yyyy'));
    let resp = await this.getColunaLinhasDevolucao(dtIni, dtFim, rows, columns, pdf, nome);
    columns = resp.columns;
    rows = resp.rows;
    this.loading.next(false);
  }

  async getColunaLinhasDevolucao(dtIni: any, dtFim: any, rows: any, columns: any, pdf: any, nome: any) {
    // tslint:disable-next-line:max-line-length
    this.parceriaDespesaDevolApi.getDevolucaoAplicacaoPeriodo(this.filtro, this.isPortal).subscribe(async (response) => {
      if (response) {
        //console.log(response);
        // tslint:disable-next-line:max-line-length
        const cols = ['Data', 'Valor', 'Motivo', 'Situação', 'Documento'];
        rows = [];
        const devolucao = response as DevolucaoAplicacao[];

        devolucao.forEach(element => {
          // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
          //element.execValorDoc = Mascaras.aplicaMascaraReais(element.execValorDoc);
          if (element.execDevolJustificaExclusao == null) {
            const datePipe = new DatePipe('en-US');
            element.execDevolDataStr = datePipe.transform(element.execDevolData, 'dd/MM/yyyy');
            element.observacao = this.utils.getExecConferirStr(element.execConferir);
            element.operacaoUsuario = this.utils.getMotivoStr(element.execDevolMotivo);
            let temp = [element.execDevolDataStr, this.mascaras.aplicaMascaraReais(element.execDevolValor), element.operacaoUsuario,
            element.observacao, element.execDevolArquivo];
            rows.push(temp);
          }
        });

        columns = cols;
        pdf.autoTable(columns, rows, {
          headerStyles: { fillColor: [8, 146, 151] },
          tableWidth: 'wrap',
          margin: { horizontal: 7, vertical: 20 },
          bodyStyles: { valign: 'top' },
          styles: { cellPadding: 0.5, fontSize: 10, overflow: 'linebreak', columnWidth: 'wrap' },
          columnStyles: { text: { columnWidth: 'auto' } }
        });
        pdf.save(nome); // Generated PDF
      }
      else {
        this.alertService.warning('Nenhum registro encontrado');
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      }
      else {
        this.alertService.warning(response.error);
      }
    }, () => this.loading.next(false));
    return { rows, columns };
  }

  aplicaMascaraReais(valor) {
    return this.mascaras.aplicaMascaraReais(valor);
  }

  consultaPagina(event) {
    let quociente = this.devolucoesAplicacoes.length / this.config.itemsPerPage;
    let resto = this.devolucoesAplicacoes.length % this.config.itemsPerPage;

    if (resto > 0) {
      quociente = quociente + 1;
    }
    this.pagina = event.currentTarget.value;
    if (this.pagina <= (quociente))
      this.config.currentPage = this.pagina;

  }

  openFiltroDev(content, filtros) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  updateMotivoFiltroDev(event) {
    if (event !== undefined) {
      this.filtro.motivo = event.id;
    } else {
      this.filtro.motivo = 0;
    }
  }

  updateSituacaoFiltroDev(event) {
    if (event !== undefined) {
      this.filtro.situacao = event.id;
    } else {
      this.filtro.situacao = 0;
    }
  }

  aplicaFiltroDev() {
    if (this.filtro == undefined) {
      this.inicializaFiltro();
    }

    this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');

    this.getDevolucaoParceria(this.filtro);
  }
}

